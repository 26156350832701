import React, {Fragment} from 'react';
import useController from "../../framework/alto/data/useController";
import computerScienceArticlesController from "../../controllers/computer_science_articles_controller";
import css from "./trees.module.css";
import View from "../../framework/alto/ui/view";
import ArticleHeadline from "../../components/articles/article-headline";
import ArticleToolbar from "../../components/articles/article-toolbar";
import ImageView from "../../framework/alto/ui/image_view";
import TreesDiagram1 from "../../images/trees-3.1.png";
import TreesDiagram2 from "../../images/trees-3.2.png";
import TreesDiagram3 from "../../images/trees-3.3.png";
import TreesDiagram4 from "../../images/trees-3.4.png";
import TreesDiagram5 from "../../images/trees-3.5.png";
import TreesDiagram6 from "../../images/trees-3.6.png";
import TreesDiagram7 from "../../images/trees-3.7.png";
import TreesDiagram8 from "../../images/trees-3.8.png";
import TreesDiagram9 from "../../images/trees-3.9.png";
import TreesDiagram10 from "../../images/trees-3.10.png";
import TreesDiagram11 from "../../images/trees-3.11.png";

let TreeArticle = () => {
    let [data] = useController(computerScienceArticlesController);

    return (
        <Fragment>
            <ArticleToolbar data={data.collection[0]}/>
            <View className={css.scroll}>
                <ArticleHeadline data={data.collection[0]}/>

                <section className={css.documentView}>
                    <View className={css.columnOne}>
                        <label><span className={css.bold}>Trees</span> are recursive data structures where a node in
                            a tree can have zero, one, or several references to other nodes and a node will have only
                            one node referencing it.  In other words, a node can have zero - many references, while a
                            reference belongs to one node.  See figure 3.1.
                            <br/> <br/>
                            To have a more in-depth understanding of trees, we will examine terms to describe a tree.
                            <br/> <br/>
                            <span className={css.bold}>Terms:</span>
                            <br/> <br/>
                            <span className={css.bold}>Parent</span> - A node with references to other nodes.  Every
                            node except the root has one parent. In figure 3.1, ‘<span className={css.bold}>A</span>’ is
                            the parent of ‘<span className={css.bold}>B</span>’ and ‘<span className={css.bold}>C</span>’.
                            <br/> <br/>
                            <span className={css.bold}>Child</span> - A node is a child of any node that references
                            it. In figure 3.1, ‘<span className={css.bold}>B</span>’ and ‘<span className={css.bold}>C</span>’
                            are children of '<span className={css.bold}>A</span>'.
                            <br/> <br/>
                            <span className={css.bold}>Descendant</span> - All the nodes that can be reached by
                            traversing a path of children from a specific node. In figure 3.1,
                            ‘<span className={css.bold}>D, E, F, H, I, J, K</span>’ are descendants of
                            ‘<span className={css.bold}>B</span>’
                            <br/> <br/>
                            <span className={css.italic}>Ancestor</span> - Given a descendant all nodes that recursively
                            have a common parent are ancestors. In figure 3.1, ‘<span className={css.bold}>A, B, D</span>’
                            are ancestors of ‘<span className={css.bold}>I</span>’
                            <br/> <br/>
                            <span className={css.bold}>Leaves</span> - Nodes that do not have any children.
                            In figure 3.1, ‘<span className={css.bold}>E, G, H, I, K</span>’ are leaves.
                        </label>
                    </View>
                    <View className={css.columnTwo}>
                        <ImageView src={TreesDiagram1} className={css.treesDiagram}/>
                    </View>
                </section>

                <section className={css.documentView}>
                    <View className={css.columnOne}>
                        <span className={css.boldLarge}>Binary Search Tree <br/></span>
                        <span className={css.bold}>in-Action with Alto.js <br/> <br/></span>
                        <label>Binary Search Tree (BST) is a common way of storing data. In a BST, the value held by a
                            node’s left child is less than or equal to the value of itself and the value held by a
                            node’s right child is greater than or equal to the value of itself.
                            <br/><br/>
                            Our data structure to describe a node in a BST will be a javascript object literal.
                            Specifically, our node will have three properties: data, left and right.
                            <br/><br/>
                            Once we have our BST generated, we will be able to traverse, find a node, find all
                            descendants, find ancestors, and more.
                            <br/><br/>
                            We will start be exploring our traversing options.
                        </label>
                    </View>
                    <View className={css.columnTwo}>
                        <ImageView src={TreesDiagram2} className={css.treesDiagram}/>
                    </View>
                </section>

                <section className={css.documentView}>
                    <View className={css.columnOne}>
                        <span className={css.bold}>Depth First Traversals with Binary Search Tree:</span>
                        <br/><br/>
                        <span className={css.bold}>Preorder</span> - Traverses a tree starting with the provided
                        node, then all the left descendants and last all of the right descendants.  Meaning, a node is
                        always visited before any of its children. (self, left, right). Preorder traversal will visit
                        our tree in figure 3.3 in the following order: <span className={css.bold}>(24, 11, 1, 8, 4, 5, 18, 84, 36)</span>
                        <br/><br/>
                        <span className={css.bold}>Inorder</span> - Traverses a tree starting with the provided
                        node’s left descendants, then on the node itself, followed by the node’s right descendants.
                        Meaning, the left subtree is visited first, then the node itself, and then the node’s right
                        subtree. (left, self, right). Inorder traversal will visit our tree in figure 3.3 in the
                        following order: <span className={css.bold}>(1, 4, 5, 8, 11, 18, 24, 36, 84)</span>
                        <br/><br/>
                        <span className={css.bold}>Postorder</span> - Traverses a tree starting with the provided
                        node’s left descendants, then on the nodes right descendants, and finally on the node itself.
                        Meaning, a node is always visited after its children.  (left, right, self). Postorder traversal
                        will visit our tree in figure 3.3 in the following
                        order: <span className={css.bold}>(5, 4, 8, 1, 18, 11, 36, 84, 24)</span>
                    </View>
                    <View className={css.columnTwo}>
                        <ImageView src={TreesDiagram3} className={css.treesDiagram}/>
                    </View>
                </section>

                <section className={css.documentView}>
                    <View className={css.columnOne}>
                        <span className={css.bold}>Preorder</span>
                        <ImageView src={TreesDiagram4} className={css.treesDiagram}/>
                        <span className={css.bold}>Inorder</span>
                        <ImageView src={TreesDiagram5} className={css.treesDiagram}/>
                    </View>
                    <View className={css.columnTwo}>
                        <span className={css.bold}>Postorder</span>
                        <ImageView src={TreesDiagram6} className={css.treesDiagram}/>
                    </View>
                </section>

                <section className={css.documentView}>
                    <View className={css.columnOne}>
                        With our three depth first search algorithms explored, we can now perform other search actions
                        on our binary search tree.
                        <br/><br/>
                        By modifying the logic of a preorder traversal, we can now find all leaves in our tree.
                        When we visit “self” or the current node given, we check to see if the node has a left and
                        right non-null data value.  If true, then we found a leaf.  Otherwise we call our findLeaves
                        method again when there is a value for either node.left, node.right or both.
                        <ImageView src={TreesDiagram7} className={css.treesDiagram}/>
                        Checking for descendants of a given node is straight forward as well.  Much like findLeaves,
                        findDescendants uses a preorder traversal and when the root data matches our lookup, we can
                        simply pass the matched node to our preorderTraversal method to walk the remaining nodes.
                        <ImageView src={TreesDiagram8} className={css.treesDiagram}/>
                    </View>
                    <View className={css.columnTwo}>
                        To find all ancestors of a given node.  We check to see if the current nodes data is less than
                        or greater than our lookup value.  If it is less than, we move root to root.left and when it is
                        greater than, we move out root to root.right.  Before we move out root reference, we get the
                        current value of root and push it into our ancestors array.  When there is a match found, we
                        return our ancestors.
                        <ImageView src={TreesDiagram9} className={css.treesDiagram}/>
                    </View>
                </section>

                <section className={css.documentView}>
                    <View className={css.columnOne}>
                        <span className={css.bold}>Breadth First Traversal with Binary Search Tree:</span>
                        <br/><br/>
                        A <span className={css.bold}>Breadth First Search</span> is a bit more involved but once it is
                        demystified its elegance is evident in its simplicity.  BSF visits our tree in levels or rows.
                        <br/><br/>
                        On first traversal, we start with 24.  On the next traversal we will be at level two.  We visit
                        level two in the following order, 11, 84.  Then for the next traversal we will visit all of
                        the level three nodes.  Here 1, 18, 36 will be visited. And so forth.
                        <br/><br/>
                        To handle a breadth first traversal, we will need to use a queue and processed queue.
                        Recursively visiting nodes, on the first traversal our queue will be [24] and the processed
                        queue will be [24] as well.  On the second traversal our queue will be [11, 84] and the
                        processed queue will be [24, 11].
                        <ImageView src={TreesDiagram10} className={css.treesDiagram}/>
                    </View>
                    <View className={css.columnTwo}>
                        <ImageView src={TreesDiagram11} className={css.treesDiagram}/>
                    </View>
                </section>

            </View>
        </Fragment>
    )

};

export default TreeArticle;